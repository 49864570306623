
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import AccessToken from "@/models/accessToken";
    import ProductPayload from "@/store/modules/product/models";
    import { ReservationModel } from "@/apiManager/reservation/reservationData";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";

    @Component
    export default class PopUpCancelReservation extends Vue
    {
        @PropSync("open", { type: Boolean }) private syncedOpen!: boolean;
        @Prop({ type: String }) private readonly reservCode!: string;

        private txtTitle: string = "";

        mounted()
        {
            this.txtTitle = this.i18n(this.getRezToursLanguageEnum.confirmation);
        }

        async onCancelReservationModalOkClick(event: Event)
        {
            this.$tStore.commit(new ReservationPayload.Mutations.ClearIntervalTimer());
            let reservation: ReservationModel = this.$tStore.state.reservationState.detail;
            let payload = new ReservationPayload.Actions.DeleteReservation(this.reservCode);

            this.$tStore.dispatch(payload);

            if (this.$tStore.state.reservationState.modalCancelReservationLogOut)
            {
                AccessToken.delete();
                this.$tStore.commit(new AuthenticationModalPayload.Mutations.SetIsAuth(false, LoginTypeEnum.None));
            }

            this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationLogOut(false));

            if (this.$tStore.state.reservationState.modalCancelReservationRedirect.length > 0)
            {
                let redirect = this.$tStore.state.reservationState.modalCancelReservationRedirect;
                this.$tStore.commit(new ReservationPayload.Mutations.SetModalCancelReservationRedirect(""));
                return this.$router.replace({ name: redirect });
            }

            if (reservation)
            {
                return this.$router.replace(
                    {
                        name: RoutesEnum.ProductDetail,
                        query:
                            {
                                prod: reservation.mainProduct.code,
                                prodPDat: reservation.productPriceDateCode.toString(),
                                dtDep: reservation.dateDepart,
                                dtRet: reservation.dateReturn
                            }
                    }
                );
            }

            this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());
            return this.$router.replace({ name: RoutesEnum.Package });
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }
    }

