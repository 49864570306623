

    import { Component, Vue } from "vue-property-decorator";
    import moment, { Duration } from "moment";
    import RoutesEnum from "@/router/routesEnum";
    import { RawLocation, Route } from "vue-router";
    import ReservationPayload from "@/store/modules/reservation/models";
    import PopUpCancelReservation from "@/app/reservation/components/PopUpCancelReservation.vue";
    import ReservationTokenModel from "@/models/reservationTokenModel";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import { ReservationModel } from "@/apiManager/reservation/reservationData";

    @Component
    export default class ReservationLayout extends Vue
    {
        timer: string = "";
        timerEndAt: number = 0;
        duration: Duration | undefined;
        private isScrolling: boolean = false;

        get components()
        {
            let components =
                {
                    "pop-up-cancel-reservation": PopUpCancelReservation
                };

            return components;
        }

        get getIntervalTimer(): number
        {
            return this.$tStore.state.reservationState.intervalTimer;
        }

        get getReservationLayoutLoading(): boolean
        {
            return this.$tStore.state.reservationState.layoutLoading;
        }

        get getReservationDetail(): ReservationModel
        {
            return this.$tStore.state.reservationState.detail;
        }

        get getRoutesEnum(): typeof RoutesEnum
        {
            return RoutesEnum;
        }

        get getCurrentRouteName(): string | undefined
        {
            return this.$route?.name ?? undefined;
        }

        get getStyleBackgroundImage(): string
        {
            return "background-image: url(" + this.getBackgroundImagePath + ");";
        }

        get getBackgroundImagePath(): string
        {
            if (this.$tStore.state.reservationState.backgroundImagePath.length > 0)
            {
                return this.$tStore.state.configState.setting.license.urlWebSiteServer + this.$tStore.state.reservationState.backgroundImagePath;
            }
            else
            {
                return "";
            }
        }

        get showModalCancelReservation(): boolean
        {
            return this.$tStore.state.reservationState.showModalCancelReservation;
        }

        set showModalCancelReservation(value: boolean)
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(value));
        }

        async mounted()
        {
            let token = new ReservationTokenModel();

            if (token.valid())
            {
                try
                {
                    let payload = new ReservationPayload.Actions.FetchReservation(token.reservationCode);
                    await this.$tStore.dispatch(payload);
                }
                catch(ex)
                {
                    ReservationTokenModel.delete();
                    return this.$router.replace({
                        name: RoutesEnum.NotFound
                    });
                }

                this.timerEndAt = token.exp * 1000;
                let diffTime = token.exp - moment().unix();
                this.duration = moment.duration(diffTime*1000, "milliseconds");
                this.UpdateTimer();
                this.$tStore.commit(new ReservationPayload.Mutations.SetIntervalTimer(setInterval(this.UpdateTimer, 1000)));

                window.addEventListener("beforeunload", this.beforePageLeave);
            }
        }

        beforeDestroy()
        {
            window.removeEventListener("beforeunload", this.beforePageLeave);
        }

        beforeRouteUpdate(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            let token: ReservationTokenModel = new ReservationTokenModel();

            if (token.valid())
            {
                if (this.timerEndAt === 0)
                {
                    this.timerEndAt = token.exp * 1000;
                    let diffTime = token.exp - moment().unix();
                    this.duration = moment.duration(diffTime*1000, "milliseconds");
                    this.UpdateTimer();
                    this.$tStore.commit(new ReservationPayload.Mutations.SetIntervalTimer(setInterval(this.UpdateTimer, 1000)));
                }

                window.addEventListener("beforeunload", this.beforePageLeave);

                return p_Next();
            }
            else
            {
                if (p_From.name === RoutesEnum.Options || p_To.name === RoutesEnum.Options)
                {
                    return p_Next();
                }

                if (p_To.name === RoutesEnum.Booking)
                {
                    this.timerEndAt = 0;
                    this.timer = "";
                    this.$tStore.commit(new ReservationPayload.Mutations.ClearIntervalTimer());
                    window.removeEventListener("beforeunload", this.beforePageLeave);
                    return p_Next();
                }
                else
                {
                    let reservation: ReservationModel = this.$tStore.state.reservationState.detail;
                    if (reservation)
                    {
                        return p_Next(
                            {
                                name: RoutesEnum.ProductDetail,
                                query:
                                {
                                    prod: reservation.mainProduct.code,
                                    prodPDat: reservation.productPriceDateCode.toString(),
                                    dtDep: reservation.dateDepart,
                                    dtRet: reservation.dateReturn
                                },
                                replace: true
                            }
                        );
                    }
                    else
                    {
                        p_Next({ name: RoutesEnum.Package, replace: true });
                    }

                }
            }

        }

        UpdateTimer()
        {
            if (Date.now() > this.timerEndAt)
            {
                if (this.getIntervalTimer)
                {
                    let token = new ReservationTokenModel();
                    let reservation: ReservationModel = this.$tStore.state.reservationState.detail;

                    this.$tStore.commit(new ReservationPayload.Mutations.ClearIntervalTimer());

                    let payload = new ReservationPayload.Actions.DeleteReservation(token.reservationCode);
                    this.$tStore.dispatch(payload);

                    let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                        this.i18n(this.getRezToursLanguageEnum.reservationExpiredTitle),
                        this.i18n(this.getRezToursLanguageEnum.reservationExpiredMsg)
                    );

                    this.$tStore.commit(payloadModal);

                    this.$router.push(
                        {
                            name: RoutesEnum.ProductDetail,
                            query:
                            {
                                prod: reservation.mainProduct.code,
                                prodPDat: reservation.productPriceDateCode.toString(),
                                dtDep: reservation.dateDepart,
                                dtRet: reservation.dateReturn
                            }
                        }
                    );
                }
                this.timer = "";
            }
            else
            {
                if (this.duration)
                {
                    this.duration = moment.duration((this.duration as any) - 1000, "milliseconds");
                    this.timer = moment(this.duration.asMilliseconds()).format("mm:ss");
                }
            }
        }

        beforeRouteLeave(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            let token: ReservationTokenModel = new ReservationTokenModel();
            if (token.valid())
            {
                if (token.isNotExpired())
                {
                    return p_Next(false);
                }
            }

            p_Next();
        }

        beforePageLeave(event: any)
        {
            if (!this.$tStore.state.reservationState.redirect)
            {
                event.returnValue = "You have unfinished changes!";
            }
            //navigator.sendBeacon()
        }

        destroyed()
        {
            window.removeEventListener("scroll", this.onScroll, true);
        }

        created()
        {
            window.addEventListener("scroll", this.onScroll, true);
        }
        onScroll()
        {
            this.isScrolling =
                document.body.scrollTop > 90 ||
                document.documentElement.scrollTop > 90;
        }
    }

